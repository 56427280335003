import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Icons from "./icons"
import "./card.sass"

const Card = ({ ...integration }) => {
  return (
    <Link
      to={`/integrations/${integration.title
        .toLowerCase()
        .replace(/\s+/g, "-")
        .slice(0, 200)}`}
    >
      <div className="integration-card">
        <div className="card-top">
          {integration.image && integration.image.asset && (
            <GatsbyImage
              image={integration.image.asset.gatsbyImageData}
              alt={integration.image.asset.originalImage}
            />
          )}

          <h3>{integration.title}</h3>
          <p>{integration.subTitle}</p>
        </div>

        <div className="card-bottom">
          <div className="available">
            <span>Available in: </span>
            <span>
              <Icons icon="express" available={integration.express} />
            </span>
            <span>
              <Icons icon="practicePro" available={integration.pro} />
            </span>
            <span>
              <Icons icon="practiceProPlus" available={integration.proPlus} />
            </span>
          </div>

          <Link
            to={`/integrations/${integration.title
              .toLowerCase()
              .replace(/\s+/g, "-")
              .slice(0, 200)}/`}
          >
            Learn More
          </Link>
        </div>

        <span
          className="bottom-color-line"
          style={{ backgroundColor: integration.colorlist || "#FED141" }}
        />
      </div>
    </Link>
  )
}

export default Card
