import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HeroHeader from "../heroHeader"
import "./header.sass"

const Header = () => {
  const {
    sanityIntegrationsPage: { heroHeader },
  } = useStaticQuery(graphql`
    query integrationsHeaderQuery {
      sanityIntegrationsPage {
        heroHeader {
          mainContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          subContent {
            _rawColumnContent(resolveReferences: { maxDepth: 5 })
          }
          featureImage {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          waveColor
        }
      }
    }
  `)

  return (
    <div id="integrations_header">
      <HeroHeader data={heroHeader} />
    </div>
  )
}

export default Header
