import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocationsContext } from "../../contexts/locationsContext"
import Card from "./card"
import "./contentCards.sass"

const ContentCards = () => {
  const {
    sanityIntegrationsPage: { integrations: integrationsData },
  } = useStaticQuery(graphql`
    {
      sanityIntegrationsPage {
        integrations {
          _key
          title
          subTitle
          express
          pro
          proPlus
          image {
            asset {
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
          colorlist
          loactions {
            locations {
              countryCode
            }
          }
        }
      }
    }
  `)

  const { currentLocation } = useContext(LocationsContext)
  const [integrations, setIntegrations] = useState(integrationsData)

  useEffect(() => {
    if (currentLocation.countryCode === "gl") {
      setIntegrations(integrationsData)
    } else {
      const filteredData = integrationsData.filter(
        integrations =>
          integrations.loactions && // prevent error, if it's null, return false
          integrations.loactions.locations
            .map(location => location.countryCode) // reconstruct country code as array of string
            .includes(currentLocation.countryCode) // chech if the country code same as current country code, then return true
      )
      setIntegrations(filteredData)
    }
  }, [currentLocation])

  return (
    <div id="integrations_card">
      <div className="container">
        {integrations.length > 0 ? ( // chech is there are integration avalible
          integrations.map(integration => (
            <Card {...integration} key={integration._key} />
          ))
        ) : (
          <p className="empty-notice">
            Sorry, no integration avalible in region:{" "}
            {currentLocation.countryName}
          </p> // if no integration avalible, return this paragraph
        )}
      </div>
    </div>
  )
}

export default ContentCards
