import React from "react"
import Layout from "../components/layout"
import SEO from "../components/integrations/seo"
import Header from "../components/integrations/header"
import Content from "../components/integrations/contentCards"

const Integrations = () => {
  return (
    <Layout>
      <div>
        <SEO />
        <Header />
        <Content />
      </div>
    </Layout>
  )
}

export default Integrations
